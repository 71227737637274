import { FC, SVGProps } from "react";

const Rotate: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6257 6.76546L7.95898 4.0988L10.6257 1.43213"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.45378 14.6032C12.3993 14.6032 14.7871 12.2154 14.7871 9.26986C14.7871 6.32434 12.3993 3.93652 9.45378 3.93652"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9.10522 9.52856L5.80539 6.22873C5.54504 5.96838 5.12293 5.96838 4.86258 6.22873L1.56275 9.52856C1.3024 9.78891 1.3024 10.211 1.56275 10.4714L4.86258 13.7712C5.12293 14.0316 5.54504 14.0316 5.80539 13.7712L9.10522 10.4714C9.36557 10.211 9.36557 9.78891 9.10522 9.52856Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Rotate;
