import { FC, useRef, ReactNode } from "react";
import FocusLock from "react-focus-lock";
import classNames from "classnames";

import ClientOnlyPortal from "./ClientOnlyPortal";
import useOnClickOutside from "hooks/useClickOutside";
import useOnKeypress from "hooks/useOnKeypress";

const selector = "#modals";

/**
 * Laying multiple dialogs on top of the other
 */
const getOtherDialogs = () => {
  if (typeof window === "undefined") {
    return [];
  }
  return Array.from(document.querySelectorAll("[role=dialog]"));
};

interface ModalProps {
  children?: ReactNode;
  className?: string;
  onDismiss: () => void;
  dismissOnClickOutside?: boolean;
  onSubmit?: React.FormEventHandler;
  noValidate?: boolean;
  isForm?: boolean;
}

const Modal: FC<ModalProps> = ({
  children,
  className,
  onDismiss,
  dismissOnClickOutside = true,
  onSubmit,
  noValidate,
  isForm,
  ...props
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(
    modalRef,
    () => {
      dismissOnClickOutside && onDismiss();
    },
    {
      exclude: getOtherDialogs,
    }
  );
  useOnKeypress(["Escape"], onDismiss);

  const mainComponent = (
    <div
      aria-modal="true"
      role="dialog"
      tabIndex={-1}
      className={classNames(
        "min-w-auto m-[10px] flex h-full  max-h-[calc(100vh_-_230px)] flex-col rounded-xlarge bg-white p-24 sm:min-w-[480px]",
        className
      )}
      ref={modalRef}
      {...props}
    >
      {children}
    </div>
  );

  return (
    <ClientOnlyPortal selector={selector}>
      <div className="absolute left-0 top-0 h-full w-screen bg-dark-blue-100 opacity-80" />
      <div className="fixed inset-0 grid place-content-center overflow-hidden bg-grey-100/10">
        <FocusLock autoFocus returnFocus>
          {isForm ? (
            <form onSubmit={onSubmit} noValidate={noValidate}>
              {mainComponent}
            </form>
          ) : (
            <>{mainComponent}</>
          )}
        </FocusLock>
      </div>
    </ClientOnlyPortal>
  );
};

export default Modal;
