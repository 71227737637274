import { createContext, FC, useContext, useMemo, ReactNode } from "react";
import { X } from "react-feather";
import { useId } from "@reach/auto-id";

import Modal from ".";

interface DialogContextValue {
  headerId?: string;
  onDismiss: () => void;
}

const DialogContext = createContext<DialogContextValue>(null!);
const useDialog = () => useContext(DialogContext);

/**
 * Denotes a header component for Dialog
 * Adds a close button
 */
const DialogHeader: FC<{
  children?: ReactNode;
  withCloseButton?: boolean;
}> = ({ children, withCloseButton = true }) => {
  const { headerId, onDismiss } = useDialog();
  return (
    <header className="space-between flex flex-shrink-0 flex-grow-0 items-center text-grey--50">
      <h1 id={headerId} className="flex-1 text-t2 font-medium">
        {children}
      </h1>
      {withCloseButton && (
        <button
          className="relative -top-4"
          aria-label="Close Dialog"
          onClick={onDismiss}
        >
          <X size={20} />
        </button>
      )}
    </header>
  );
};

const DialogContent: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className="my-24 flex-shrink flex-grow overflow-y-auto px-4">
      {children}
    </div>
  );
};

const DialogFooter: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className="flex flex-shrink-0 flex-grow-0 items-center justify-end gap-x-12">
      {children}
    </div>
  );
};

interface DialogProps {
  visible: boolean;
  onDismiss: () => void;
  children?: ReactNode;
  dismissOnClickOutside?: boolean;
  className?: string;
  onSubmit?: React.FormEventHandler;
  noValidate?: boolean;
  isForm?: boolean;
}

/**
 * Dialog component used for modals
 * Includes static helps for easy use, header, content, footer
 */
const Dialog: FC<DialogProps> = ({
  visible,
  onDismiss,
  children,
  dismissOnClickOutside,
  className,
  onSubmit,
  noValidate,
  isForm,
}) => {
  const headerId = useId();
  const contextValue = useMemo(
    () => ({
      headerId,
      onDismiss,
    }),
    [headerId, onDismiss]
  );

  if (!visible) {
    return null;
  }

  return (
    <DialogContext.Provider value={contextValue}>
      <Modal
        aria-labelledby={headerId}
        onDismiss={onDismiss}
        dismissOnClickOutside={dismissOnClickOutside}
        className={className}
        onSubmit={onSubmit}
        noValidate={noValidate}
        isForm={isForm}
      >
        {children}
      </Modal>
    </DialogContext.Provider>
  );
};

const dialogElements = {
  Header: DialogHeader,
  Content: DialogContent,
  Footer: DialogFooter,
};

export default Object.assign(Dialog, dialogElements);
