import { FC, SVGProps } from "react";

const Authority: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="16"
        y="16"
        width="6"
        height="6"
        rx="3"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="2"
        y="16"
        width="6"
        height="6"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="16"
        y="2"
        width="6"
        height="6"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="8"
        y="2"
        width="6"
        height="6"
        rx="3"
        transform="rotate(90 8 2)"
        strokeWidth="2"
      />
      <path
        d="M19 8L19 12L5 12L5 16"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 5H16" strokeWidth="2" strokeLinecap="round" />
      <path d="M8 19H16" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default Authority;
