import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import classNames from "classnames";
import { Loader } from "react-feather";

export type ButtonSizes = "SMALL" | "REGULAR" | "LARGE";
export type ButtonVariants = "PRIMARY" | "SECONDARY" | "TEXT";
export type ButtonModes =
  | "NEUTRAL_LIGHT"
  | "DARK"
  | "BRIGHT"
  | "BOLD"
  | "DESTRUCTIVE";

const verticalPadding = {
  SMALL: ["py-4"],
  REGULAR: ["py-8"],
  LARGE: ["py-16"],
};

const horizontalPadding = {
  SMALL: ["px-8"],
  REGULAR: ["px-16"],
  LARGE: ["px-24"],
};

const isPrimaryVariant = (variant: ButtonVariants) => variant === "PRIMARY";
const isSecondaryVariant = (variant: ButtonVariants) => variant === "SECONDARY";
const isTextVariant = (variant: ButtonVariants) => variant === "TEXT";

const getModeStyles = (variant: ButtonVariants) => ({
  NEUTRAL_LIGHT: [
    {
      "bg-grey-25 hover:bg-grey-50 text-grey--50": isPrimaryVariant(variant),
      "border-2 border-grey-50 hover:bg-grey-50 text-grey--50":
        isSecondaryVariant(variant),
      "hover:bg-grey-25 text-grey--50": isTextVariant(variant),
    },
  ],
  DARK: [
    {
      "bg-dark-blue-100 hover:bg-dark-blue-75 text-white":
        isPrimaryVariant(variant),
      "border-2 border-dark-blue-75 hover:bg-dark-blue-25 text-dark-blue-100":
        isSecondaryVariant(variant),
      "hover:bg-dark-blue-25 text-dark-blue-100": isTextVariant(variant),
    },
  ],
  BRIGHT: [
    {
      "bg-blue-100 hover:bg-blue-75 text-white": isPrimaryVariant(variant),
      "border-2 border-blue-100 hover:bg-blue-25 text-blue--50":
        isSecondaryVariant(variant),
      "hover:bg-blue-25 text-blue-100": isTextVariant(variant),
    },
  ],
  BOLD: [
    {
      "bg-green-100 hover:bg-green-75 text-gray--50": isPrimaryVariant(variant),
      "border-2 border-green-100 hover:bg-green-25 text-green--50":
        isSecondaryVariant(variant),
      "hover:bg-green-25 text-green--50": isTextVariant(variant),
    },
  ],
  DESTRUCTIVE: [
    {
      "bg-red-100 hover:bg-red-75 text-white": isPrimaryVariant(variant),
      "border-2 border-red-100 hover:bg-red-25 text-red-100":
        isSecondaryVariant(variant),
      "hover:bg-red-25 text-red-100": isTextVariant(variant),
    },
  ],
});

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  mode?: ButtonModes;
  size?: ButtonSizes;
  block?: boolean;
  icon?: ReactNode;
  loading?: boolean;
}

const Button: FC<ButtonProps> = ({
  block,
  children,
  size = "REGULAR",
  variant = "PRIMARY",
  mode = "BRIGHT",
  type = "button",
  className,
  icon = null,
  loading,
  disabled,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        "relative flex items-center justify-center rounded-xlarge text-t4 font-medium transition-colors disabled:cursor-not-allowed disabled:opacity-75",
        verticalPadding[size],
        horizontalPadding[size],
        getModeStyles(variant)[mode],
        block ? "w-full" : "",
        className
      )}
      disabled={loading || disabled}
      type={type}
      {...rest}
    >
      <div
        className={classNames(
          {
            invisible: loading,
          },
          "flex items-center justify-center gap-x-8"
        )}
      >
        {icon}
        {children}
      </div>
      {loading && <Loader className="absolute animate-spin " />}
    </button>
  );
};

export default Button;
