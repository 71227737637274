import { useEffect, RefObject } from "react";

import { DROPDOWN_CONTAINER } from "constants/strings";

/* Premature return is best to keep useEffect readable */
/* eslint-disable consistent-return */

const clickedOnScrollbar = (e: MouseEvent) =>
  document.documentElement.clientWidth <= e.clientX ||
  document.documentElement.clientHeight <= e.clientY;

const dropdowns =
  typeof window === "undefined"
    ? null
    : document.getElementById(DROPDOWN_CONTAINER);

interface ClickOutsideOptions {
  /** disable the callback */
  disable?: boolean;
  /**  event types to listen for. Defaults to 'mousedown' and 'touchstart' */
  eventTypes?: string[];
  /** click on these elements are excluded */
  exclude?: Element[] | (() => Element[]);
}

/**
 * Custom hook to get a callback on clicking outside
 * @param ref Reference to the DOM element
 * @param callback Function to be triggered on click
 * @param config configuration object
 */
function useOnClickOutside(
  ref: RefObject<HTMLElement | SVGGElement | null>,
  callback: Function,
  {
    disable = false,
    eventTypes = ["mousedown", "touchstart"],
    exclude = [],
  }: ClickOutsideOptions = {}
) {
  useEffect(() => {
    if (!ref || !callback) {
      return;
    }

    function handler(event: Event) {
      if (!ref.current) {
        return;
      }
      if (clickedOnScrollbar(event as MouseEvent)) {
        return;
      }
      const finalExclude = (() => {
        if (typeof exclude === "function") {
          return [...exclude(), dropdowns];
        }
        return [...exclude, dropdowns];
      })();
      if (
        finalExclude.some(
          (element) =>
            element instanceof Element && element.contains(event.target as Node)
        )
      ) {
        return;
      }
      const { target } = event;
      const element = ref.current;

      if (!(element instanceof Element)) {
        console.warn("Ref not of type element", element);
        return;
      }

      if (!element.contains(target as Node)) {
        callback(event);
      }
    }

    function removeHandlers() {
      eventTypes.forEach((type) => {
        document.removeEventListener(type, handler);
      });
    }

    function addHandlers() {
      eventTypes.forEach((type) => {
        document.addEventListener(type, handler);
      });
    }

    if (disable) {
      removeHandlers();
      return;
    }
    addHandlers();

    return () => {
      removeHandlers();
    };
  }, [callback, ref, eventTypes, disable, exclude]);
}

export default useOnClickOutside;
