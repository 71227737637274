import { FC, SVGProps } from "react";

const OxleyLogo: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="#86EFC7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M24.6484 24.54C24.3692 29.2591 20.4546 33 15.6665 33C10.8784 33 6.96385 29.2591 6.68465 24.54H10.2945C10.5654 27.2687 12.8671 29.4 15.6665 29.4C18.4659 29.4 20.7677 27.2687 21.0386 24.54H24.6484Z" />
      <path d="M24.6573 23.64H21.0534C20.8682 20.8255 18.5272 18.6 15.6665 18.6C12.8059 18.6 10.4649 20.8255 10.2797 23.64H6.67578C6.8647 18.8363 10.8177 15 15.6665 15C20.5154 15 24.4684 18.8363 24.6573 23.64Z" />
      <path d="M41.2133 33H37.6094C37.4242 30.1855 35.0832 27.96 32.2225 27.96C29.3619 27.96 27.0208 30.1855 26.8356 33H23.2318C23.4207 28.1963 27.3737 24.36 32.2225 24.36C37.0714 24.36 41.0244 28.1963 41.2133 33Z" />
      <path d="M41.1956 15C40.9164 19.7191 37.0018 23.46 32.2137 23.46C27.4256 23.46 23.511 19.7191 23.2318 15H26.8416C27.1125 17.7287 29.4143 19.86 32.2137 19.86C35.0131 19.86 37.3148 17.7287 37.5857 15H41.1956Z" />
    </svg>
  );
};

export default OxleyLogo;
