import { useRef, useEffect, useState, FC, ReactNode } from "react";
import { createPortal } from "react-dom";

interface ClientOnlyPortalProps {
  children?: ReactNode;
  selector: string;
}

const ClientOnlyPortal: FC<ClientOnlyPortalProps> = ({
  children,
  selector,
}) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    /* Create new child and append to the parent selector */
    const el = document.createElement("div");
    const parent = document.querySelector(selector);

    if (!parent) {
      console.warn("No parent found for the client-only portal", { selector });
    }

    parent?.appendChild(el);
    ref.current = el;
    setMounted(true);

    return () => {
      parent?.removeChild(el);
    };
  }, [selector]);

  return mounted ? createPortal(children, ref.current!) : null;
};

export default ClientOnlyPortal;
