import { useEffect, useState } from "react";

/**
 * React custom hook to detect and invoke callback when keys are pressed on keyboard
 * @param targetKeys array of keys to target
 * @param callback function called when target key is pressed
 * @param target Target element on which listener is bound
 */
function useOnKeypress(
  targetKeys: string[],
  callback: Function = () => {},
  target = window
) {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = ({ key }: { key: string }) => {
    if (targetKeys.includes(key)) {
      setKeyPressed(true);
      callback(key);
    }
  };
  const upHandler = ({ key }: { key: string }) => {
    if (targetKeys.includes(key)) {
      setKeyPressed(false);
    }
  };
  useEffect(() => {
    target.addEventListener("keydown", downHandler);
    target.addEventListener("keyup", upHandler);

    return () => {
      target.removeEventListener("keydown", downHandler);
      target.removeEventListener("keyup", upHandler);
    };
  }, [target]); // eslint-disable-line
  /* 
    Adding downHandler and upHandler in dependencies would mean that we have to pass the functions through useCallback.
    While this is possible, it makes for a very opaque dependency as future users of the hook will be aware of this specification.
  */
  return keyPressed;
}

export default useOnKeypress;
